import { useLocation } from "@reach/router"
import React, { createContext, useContext, useState } from "react"
import toast from "react-hot-toast"

import {
  postAuthSignup,
  postLoginUser,
  postOAuthLogin,
  postOAuthLoginLearn,
} from "../api"

export const AuthContext = createContext({})

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const location = useLocation()
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [partners] = useState(null)
  const [walletData, setWalletData] = useState({})
  const [walletAddress, setWalletAddress] = useState(null)
  const [walletType, setWalletType] = useState(null)

  const handleWalletData = data => {
    localStorage.setItem("walletData", JSON.stringify(data))
    setWalletData(data)
  }

  const login = async (email, password) => {
    if (!isLoading) {
      setIsLoading(true)
      let errorReturn
      const walletData = {
        walletAddress: walletAddress,
        walletType: walletType,
      }
      return postLoginUser({ email, password, walletData })
        .then(async res => {
          if (res.status === 200 && res.data.access_token) {
            localStorage.setItem("token", res.data.access_token)
            localStorage.setItem("user", JSON.stringify(res.data.user))
            setIsLoggedIn(true)
            errorReturn = { status: "ok", message: "success", data: res.data }
            setUser(res.data.user)
            setIsLoading(false)
            setWalletData({})
            localStorage.removeItem("walletData")
            return errorReturn
          } else {
            setIsLoading(false)
            throw new Error("Non 200 response")
          }
        })
        .catch(err => {
          errorReturn = {
            status: "error",
            message:
              err?.response?.data?.detail || "Email or password is invalid",
          }
          setWalletData({})
          setIsLoading(false)
          return errorReturn
        })
    }
  }

  const oAuthLogin = async (token, platform) => {
    setIsLoading(true)
    const { signupSource, signupPartnerId } = getSignupParams()
    const walletData = {
      walletAddress: walletAddress,
      walletType: walletType,
    }
    return postOAuthLogin(
      token,
      platform,
      walletData,
      signupSource,
      signupPartnerId
    )
      .then(async res => {
        if (res.status === 200 && res.data.access_token) {
          localStorage.setItem("token", res.data.access_token)
          localStorage.setItem("user", JSON.stringify(res.data.user))
          setUser(res.data.user)
          setIsLoggedIn(true)
          setWalletData({})
          localStorage.removeItem("walletData")
          setIsLoading(false)
          return { status: "ok", message: "success", data: res.data }
        } else {
          setIsLoading(false)
          localStorage.removeItem("walletData")
          window.open(`${process.env.GATSBY_MEMBERS_URL}/login`, "_self")
          // throw new Error("Non 200 response");
          return { status: "error", message: "Unable to Login" }
        }
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.detail)
        return { status: "error", message: err?.response?.data?.detail }
      })
  }

  const oAuthLoginLearn = async payload => {
    setIsLoading(true)
    return postOAuthLoginLearn(payload)
      .then(async res => {
        if (res.status === 200 && res.data.access_token) {
          localStorage.setItem("token", res.data.access_token)
          localStorage.setItem("user", JSON.stringify(res.data.user))
          setUser(res.data.user)
          setIsLoggedIn(true)
          setWalletData({})
          localStorage.removeItem("walletData")
          setIsLoading(false)
          return { status: "ok", message: "success", data: res.data }
        } else {
          setIsLoading(false)
          localStorage.removeItem("walletData")
          window.open(`${process.env.GATSBY_MEMBERS_URL}/login`, "_self")
          // throw new Error("Non 200 response");
          return { status: "error", message: "Unable to Login" }
        }
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.detail)
        return { status: "error", message: err?.response?.data?.detail }
      })
  }

  const signup = async (email, password, walletData) => {
    if (!isLoading) {
      const { walletName } = walletData || {}
      const { signupSource, signupPartnerId } = getSignupParams()
      const payload = {
        email,
        password,
        signup_wallet_type: walletType || undefined,
        signup_wallet_address: walletAddress || undefined,
        signup_wallet_name: walletName || undefined,
        signup_source: signupSource,
        signup_partner_id: signupPartnerId,
      }
      console.log("Signup payload: ", payload)
      setIsLoading(true)
      return postAuthSignup(payload)
        .then(async res => {
          if (res.status === 200 && res.data.access_token) {
            localStorage.setItem("token", res.data.access_token)
            localStorage.setItem("user", JSON.stringify(res.data.user))
            setUser(res.data.user)
            setIsLoggedIn(true)
            setIsLoading(false)
            setWalletData({})
            return { status: "ok", message: "success", data: res.data }
          } else {
            throw new Error("Non 200 response")
          }
        })
        .catch(err => {
          setIsLoading(false)
          setWalletData({})
          return {
            status: "error",
            message: err.response.data.detail || "Email or password is invalid",
          }
        })
    }
  }

  const learnSignup = async (email, password, id) => {
    if (!isLoading) {
      setIsLoading(true)
      const { signupSource, shareUtm } = getSignupParams()
      return postAuthSignup({
        email,
        password,
        signup_source: signupSource,
        signup_learn_id: id,
        signup_learn_utm: shareUtm || undefined,
      })
        .then(async res => {
          if (res.status === 200 && res.data.access_token) {
            localStorage.setItem("token", res.data.access_token)
            localStorage.setItem("user", JSON.stringify(res.data.user))
            setUser(res.data.user)
            setIsLoggedIn(true)
            setIsLoading(false)
            return { status: "ok", message: "success", data: res.data }
          } else {
            throw new Error("Non 200 response")
          }
        })
        .catch(err => {
          setIsLoading(false)
          setWalletData({})
          return {
            status: "error",
            message:
              err?.response?.data?.detail || "Email or password is invalid",
          }
        })
    }
  }

  const getSignupParams = () => {
    const pathName = location.pathname
    const query = location.search

    const shareUtm = query.replace("?share_utm=", "")

    let signupSource = undefined
    let signupPartnerId = undefined
    if (pathName.startsWith("/deals")) {
      signupSource = "web_deals"
    } else if (pathName.startsWith("/learns")) {
      signupSource = "learn"
    } else {
      signupSource = "web_partner"
      signupPartnerId = pathName.replaceAll("/", "")
    }

    return { signupSource, signupPartnerId, shareUtm }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        isLoading,
        setIsLoading,
        partners,
        handleWalletData,
        walletData,
        setWalletData,
        setWalletAddress,
        setWalletType,
        login,
        oAuthLogin,
        signup,
        learnSignup,
        oAuthLoginLearn,
        getSignupParams,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
