import React, { createContext, useContext } from "react"
import { useLocation } from "@reach/router"
import { postPublicCourseAction } from "../api"

export const LearnContext = createContext({})

export function useLearn() {
  return useContext(LearnContext)
}

export const LearnProvider = ({ children }) => {
  const location = useLocation()

  const viewCourse = async courseId => {
    courseAction(courseId, "view")
  }

  const completeCourse = async courseId => {
    courseAction(courseId, "complete")
  }

  const convertCourse = async courseId => {
    courseAction(courseId, "convert")
  }

  const courseAction = async (courseId, action) => {
    const query = location.search
    const shareUtm = query.replace("?share_utm=", "")
    const payload = {
      course_id: courseId,
      action: action,
      share_utm: shareUtm || undefined,
    }
    postPublicCourseAction(payload)
  }

  return (
    <LearnContext.Provider
      value={{
        viewCourse,
        completeCourse,
        convertCourse,
      }}
    >
      {children}
    </LearnContext.Provider>
  )
}
