import axios from "axios"

export const getBearerToken = () => {
  if (typeof localStorage === "undefined") {
    return "Bearer"
  }
  const token = localStorage.getItem("token")
  return token ? `Bearer ${token}` : "Bearer"
}

export const setBearerToken = async config => {
  const token = getBearerToken()
  config.headers.Authorization = token
  return config
}

export const handleConfigError = error => {
  return Promise.reject(error)
}

export const axiosAssetDashApi = axios.create({
  baseURL: process.env.GATSBY_API_URL,
})
axiosAssetDashApi.interceptors.request.use(setBearerToken, handleConfigError)

export const assetDashApiFetcher = async url =>
  await axiosAssetDashApi.get(url).then(res => res.data)

export const getUserWalletStatus = id => {
  return axiosAssetDashApi.get(`web/wallets/${id}`)
}

export const postAuthSignup = async formValues => {
  return await axiosAssetDashApi.post("web/auth/signup", formValues)
}

export const postLoginUser = async ({ email, password, walletData }) => {
  const urlencoded = new URLSearchParams()
  urlencoded.append("username", email)
  urlencoded.append("password", password)

  let loginURL = "web/auth/login2"
  if (walletData?.walletAddress) {
    loginURL += `?login_wallet_type=${walletData.walletType}&login_wallet_address=${walletData.walletAddress}`
  }

  if (walletData?.walletName) {
    loginURL += `&login_wallet_name=${walletData.walletName}`
  }

  return await axiosAssetDashApi.post(loginURL, urlencoded, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
}

export const postOAuthLogin = async (
  token,
  platform,
  walletData = {},
  signupSource = undefined,
  signupPartnerId = undefined
) => {
  let payload = {}
  const walletAddress = walletData.walletAddress || undefined
  const walletType = walletData.walletType || undefined
  if (walletData) {
    payload = {
      token,
      backend: platform,
      platform_name: "web",
      signup_wallet_type: walletType,
      signup_wallet_address: walletAddress,
      signup_source: signupSource,
      signup_partner_id: signupPartnerId,
    }
  } else {
    payload = {
      token,
      backend: platform,
      platform_name: "web",
    }
  }
  return await axiosAssetDashApi.post("web/auth/sign_in", payload)
}

export const postOAuthLoginLearn = async payload => {
  return await axiosAssetDashApi.post("web/auth/sign_in", payload)
}

export const postPublicCourseAction = async payload => {
  return await axiosAssetDashApi.post("web/learn/public_course/action", payload)
}
