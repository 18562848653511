import React, { createContext } from "react"
import { useTheme } from "../hooks/useTheme"

export const ThemeContext = createContext(["dark", () => undefined])

export const ThemeProvider = ({ children }) => {
  const { theme, toggleTheme } = useTheme();

  return (
    <ThemeContext.Provider value={[theme, toggleTheme]}>
      <div className={theme}>
        <>{children}</>
      </div>
    </ThemeContext.Provider>
  )
}
