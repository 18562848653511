import "./src/styles/animate.css";
import "./src/styles/global.css";
import "./static/fonts/fonts.css";

import { LocationProvider } from "@reach/router";
import React from "react";
import { AuthProvider } from "./src/shared/hooks/useAuth";
import { LearnProvider } from "./src/shared/hooks/useLearn";
import { LearnPlayerProvider } from "./src/shared/hooks/useLearnPlayer";
import { ThemeProvider } from "./src/shared/providers/ThemeProvider";


export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <LocationProvider>
      <AuthProvider>
        <LearnPlayerProvider>
          <LearnProvider>
            {element}
          </LearnProvider>
        </LearnPlayerProvider>
      </AuthProvider>
    </LocationProvider>
  </ThemeProvider>
)