import * as React from "react"
import { createContext, useContext, useState } from "react"

export const LearnContext = createContext({})

export function useLearnPlayer() {
  return useContext(LearnContext)
}

export const LearnPlayerProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState("steps")
  const [activeQuestion, setActiveQuestion] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectedAnswer, setSelectedAnswer] = useState(0)


  const resetData = () => {
    setActiveStep("steps")
    setActiveQuestion(0)
    setCurrentIndex(0)
  }

  return (
    <LearnContext.Provider
      value={{
        activeStep,
        setActiveStep,
        activeQuestion,
        setActiveQuestion,
        currentIndex,
        setCurrentIndex,
        selectedAnswer,
        setSelectedAnswer,
        resetData
      }}
    >
      {children}
    </LearnContext.Provider>
  )
}
